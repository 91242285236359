<template>
<!-- 智慧化健身房—健身房大屏展现版本 -->
    <div>
        <div class="posit-left">
            <announcement></announcement>
            <anletfcenter style="margin:0.22rem 0rem "></anletfcenter>
            <anleftbottom></anleftbottom>
        </div>
        <div class="people-top">
            <ancentertop style="margin-bottom:0.22rem;"></ancentertop>
            <ancenterbottom></ancenterbottom>
        </div>
        <div class="people-right">
            <anrighttop style="margin-bottom:0.30rem"></anrighttop>

        </div>
    </div>
</template>

<script>
export default {
    components: {
        announcement: () => import('../charts/peopletwo/announcement.vue'),
        anletfcenter: () => import('../charts/peopletwo/anletfcenter.vue'),
        anleftbottom: () => import('../charts/peopletwo/anleftbottom.vue'),
        ancentertop: () => import('../charts/peopletwo/ancentertop.vue'),
        ancenterbottom: () => import('../charts/peopletwo/ancenterbottom.vue'),
        anrighttop: () => import('../charts/peopletwo/anrighttop.vue'),
        anrightbottom: () => import('../charts/peopletwo/anrightbottom.vue'),
    }
}
</script>

<style scoped>
.people-top{
    position: absolute;
    top: 1.36rem;
    left:34.8%;
}
.people-right{
    position: absolute;
    top: 1.27rem;
    right:2.4%;
}
</style>